


















import { Component, Vue, Watch } from 'vue-property-decorator'
import ReportViewer from './components/ReportViewer.vue'

@Component({
  components: {
    ReportViewer,
  },
})
export default class courseMaterial extends Vue {
  private get isChildRoute(): boolean {
    return this.$route.name !== 'report'
  }

  private get menuList(): Array<any> {
    return [
      {
        key: 'term',
        label: this.$t('eGrade.termReport'),
      },
      {
        key: 'yearly',
        label: this.$t('eGrade.yearlyReport'),
      },
    ]
  }

  private get type(): any {
    return this.$route.params.reportType || ''
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    if (to.name === 'report' && !to.params.reportType) {
      this.$router.replace({ name: 'report', params: { reportType: 'term' } }).catch(err => {})
    }
  }

  private changeType({ item, key, keyPath }): void {
    this.$router.push({ name: 'report', params: { reportType: key } }).catch(err => {})
  }
}
