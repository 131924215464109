
















































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import TableTotal from '@/components/TableTotal.vue'
import moment from 'moment'
import FlexTooltip from '@/components/FlexTooltip.vue'
import filterGroup from '@/components/filterGroup'
import { GradeController, DropDownController } from '@/services/request.service'
import {
  clearEmptyArray,
  toPage,
  handleBatchDownload,
  getToken,
  getSchoolInfo,
} from '@/utils/utils'
import PreviewModal from '@/components/Report/PreviewModal.vue'
import saveAs from '@/utils/FileSaver'
import debounce from 'lodash/debounce'
import ConfirmModal from './ConfirmModal.vue'
import { exportXlsx, writeFile } from '@/utils/xlsx'
import EventBus from '@/utils/EventBus'
import CommentProgress from './CommentProgress.vue'
import { createPagination, waitFor } from '@/constant/constant'
import cloneDeep from 'lodash/cloneDeep'
import OperationsGroup from '@/components/OperationsGroup'
Component.registerHooks(['beforeRouteLeave'])

const operations: any = {
  high: function(periodId, studentId) {
    return GradeController.getUpperSchoolReport(periodId, studentId)
  },
  highYear: function(periodId, studentId) {
    return GradeController.getUpperSchoolAnnualReport(studentId, periodId)
  },
  middle: function(periodId, studentId) {
    return GradeController.getMidSchoolReport(periodId, studentId)
  },
  middleYear: function(periodId, studentId) {
    return GradeController.getMidSchoolAnnualReport(studentId, periodId)
  },
  elementary: function(periodId, studentId) {
    return GradeController.getGradeReportDetail(studentId, periodId)
  },
  yearlyPeriod: function(schoolYearId) {
    return GradeController.getPeriodPeriodByType(schoolYearId)
  },
  termPeriod: function(schoolYearId) {
    return DropDownController.getPeriodCascadeForReport(schoolYearId)
  },
}
@Component({
  components: {
    FlexTooltip,
    filterGroup,
    PreviewModal,
    TableTotal,
    ConfirmModal,
    CommentProgress,
    OperationsGroup,
  },
})
export default class Report extends Vue {
  private advisories: any = []
  private data: any = []
  private filters: any = {
    homeroomId: [],
    schoolYearId: undefined,
    timeId: undefined,
    periodId: undefined,
    name: '',
  }
  /**
   * 1101 当前
   * 1100 将来
   * 1102 过去
   */
  private schoolYearType: any = '2021-2022'
  private campusCode: any = {
    1231: 'elementary',
    1232: 'middle',
    1233: 'high',
  }
  private homerooms: any = []
  private schoolYearList: any = []
  private periods: any = []
  private downloading: boolean = false
  private editStudent: any = {}
  private editVisible: boolean = false
  private generating: boolean = false
  private isLast: boolean = false
  private loading: boolean = false
  private pagination: any = createPagination({})
  private previewVisible: boolean = false
  private progressData: any = {}
  private progressVis: any = false
  private reportData: any = {}
  private selectedRows: any = []
  // private selectedRowKeys: any = []
  private sending: boolean = false
  private generateReporting: boolean = false
  // 生成报告进度完成
  private result: boolean = false

  private unavailableStudent: boolean = true
  private moment = moment
  private periodStatus = ''
  // 是否生成过报告
  private periodReport: boolean = false
  // 轮询定时器
  private timer: any = null

  private schedule: any = 0

  private tipsVisible = true
  private campusType = ''
  private previewId = -1
  private commentsCtrl = {
    courseTeacher: false,
    headteacher: false,
    tutor: false,
    lbTeacher: false,
  }
  private resultVis: any = false
  private sendResult: any = []
  private exportLoading: any = false
  private exportYearlyLoading: any = false
  private regenerating: any = false

  private reqId = 0

  // 全选学生本地保存
  private allStudentsIds: any = []

  private get columns(): Array<any> {
    return [
      {
        dataIndex: 'fullName',
        key: 'name',
        width: 300 * this.ratio,
        title: this.$t('common.studentName'),
        ellipsis: true,
        scopedSlots: { customRender: 'name' },
        fixed: 'left',
      },
      {
        dataIndex: 'studentNum',
        width: 150 * this.ratio,
        ellipsis: true,
        title: this.$t('common.studentId'),
        scopedSlots: { customRender: 'commonBlock' },
      },
      this.campusType !== '1231'
        ? {
            dataIndex: 'advisory',
            ellipsis: true,
            title: this.$t('common.advisory'),
            scopedSlots: { customRender: 'commonBlock' },
          }
        : {
            dataIndex: 'className',
            width: 150 * this.ratio,
            title: this.$t('common.homeroom'),
          },
      // {
      //   dataIndex: 'gender',
      //   ellipsis: true,
      //   title: this.$t('myClass.student.gender'),
      //   scopedSlots: { customRender: 'gender' },
      // },

      this.campusType === '1231' && {
        key: 'gradeAssessment',
        title: this.$t('eGrade.gradeAssessment'),
        width: 200 * this.ratio,
        scopedSlots: { customRender: 'gradeAssessment' },
        filterDropdown: true, // 自定义的列筛选功能，我们占位为信息提示Icon的位置
        filterIcon: () => {
          return this.$createElement(
            'a-tooltip',
            {
              props: {
                title: this.$t('eGrade.assessmentTips'),
                getPopupContainer: () => this.$document.body,
              },
            },
            [
              this.$createElement('a-icon', {
                props: {
                  type: 'question-circle',
                },
              }),
            ]
          )
        },
      },
      this.commentsCtrl.headteacher && {
        key: 'headteacherRemark',
        title: this.$t('eGrade.headteacherRemark'),
        width: 200 * this.ratio,
        scopedSlots: { customRender: 'headteacherRemark' },
        filterDropdown: true, // 自定义的列筛选功能，我们占位为信息提示Icon的位置
        filterIcon: () => {
          return this.$createElement(
            'a-tooltip',
            {
              props: {
                title: this.$t('eGrade.commentTips'),
                getPopupContainer: () => this.$document.body,
              },
            },
            [
              this.$createElement('a-icon', {
                props: {
                  type: 'question-circle',
                },
              }),
            ]
          )
        },
      },
      this.commentsCtrl.tutor && {
        key: 'advisorRemark',
        title: this.$t('eGrade.advisorRemark'),
        width: 200 * this.ratio,
        scopedSlots: { customRender: 'advisorRemark' },
        filterDropdown: true, // 自定义的列筛选功能，我们占位为信息提示Icon的位置
        filterIcon: () => {
          return this.$createElement(
            'a-tooltip',
            {
              props: {
                title: this.$t('eGrade.commentTips'),
                getPopupContainer: () => this.$document.body,
              },
            },
            [
              this.$createElement('a-icon', {
                props: {
                  type: 'question-circle',
                },
              }),
            ]
          )
        },
      },
      this.commentsCtrl.courseTeacher && {
        key: 'subjectTeacherRemark',
        title: this.$t('eGrade.subjectTeacherRemark'),
        width: 200 * this.ratio,
        scopedSlots: { customRender: 'subjectTeacherRemark' },
        filterDropdown: true, // 自定义的列筛选功能，我们占位为信息提示Icon的位置
        filterIcon: () => {
          return this.$createElement(
            'a-tooltip',
            {
              props: {
                title: this.$t('eGrade.commentTips'),
                getPopupContainer: () => this.$document.body,
              },
            },
            [
              this.$createElement('a-icon', {
                props: {
                  type: 'question-circle',
                },
              }),
            ]
          )
        },
      },
      this.commentsCtrl.lbTeacher && {
        key: 'LBRemark',
        title: this.$t('eGrade.LBRemark'),
        width: 200 * this.ratio,
        scopedSlots: { customRender: 'lbRemark' },
        filterDropdown: true, // 自定义的列筛选功能，我们占位为信息提示Icon的位置
        filterIcon: () => {
          return this.$createElement(
            'a-tooltip',
            {
              props: {
                title: this.$t('eGrade.commentTips'),
                getPopupContainer: () => this.$document.body,
              },
            },
            [
              this.$createElement('a-icon', {
                props: {
                  type: 'question-circle',
                },
              }),
            ]
          )
        },
      },
      {
        key: 'operations',
        width: 220,
        title: this.$t('common.operations'),
        scopedSlots: { customRender: 'operations' },
        fixed: 'right',
      },
    ].filter(item => item)
  }
  private get excelColumns(): Array<any> {
    return [
      {
        dataIndex: 'lastName',
        title: this.$t('common.surname'),
        width: 10,
      },
      {
        dataIndex: 'enName',
        title: this.$t('common.enName'),
        width: 10,
      },
      {
        dataIndex: 'firstName',
        title: this.$t('common.givenName'),
        width: 10,
      },
      {
        dataIndex: 'name',
        title: this.$t('common.cnName'),
        width: 10,
      },
      {
        dataIndex: 'studentNum',
        title: this.$t('common.studentId'),
        width: 15,
      },
      {
        dataIndex: 'gradeName',
        title: this.$t('common.grade'),
        width: 15,
      },
      {
        dataIndex: 'className',
        title: this.$t('common.homeroom'),
        width: 15,
      },
      {
        dataIndex: 'headTeachers',
        title: this.$t('common.headTeacher'),
        width: 20,
      },
      {
        dataIndex: 'headTeacherComments',
        title: this.$t('grading.report.headteacherComments'),
        width: 20,
      },
      {
        dataIndex: 'houseName',
        title: this.$t('common.advisory'),
        width: 20,
      },
      {
        dataIndex: 'tutorTeachers',
        title: 'Advisor',
        width: 20,
      },
      {
        dataIndex: 'advisorComments',
        title: this.$t('grading.report.advisorComments'),
        width: 20,
      },
      {
        dataIndex: 'subject',
        title: this.$t('common.subject'),
        width: 20,
      },
      {
        dataIndex: 'course',
        title: this.$t('common.subjectClass'),
        width: 20,
      },
      {
        dataIndex: 'courseTeachers',
        title: this.$t('common.subjectTeacher'),
        width: 20,
      },
      {
        dataIndex: 'subjectTeacherComments',
        title: this.$t('grading.report.subjectTeacherComments'),
        width: 20,
      },
      this.campusType === '1231' && {
        dataIndex: 'gradeAssessment',
        title: this.$t('eGrade.gradeAssessment'),
        width: 20,
      },
      // {
      //   dataIndex: 'levelOfMastery',
      //   title: 'Semester1 Score',
      //   width: 20,
      // },
    ].filter(item => item)
  }
  private get locale(): any {
    return this.$store.state.locale
  }
  private get operationAuths(): any {
    return this.$store.state.operationAuths
  }
  private get schoolYearId(): any {
    return this.$store.state.currentSchoolYear.schoolYearId
  }
  private get selectedRowKeys(): any {
    return this.selectedRows.map(item => item.studentId)
  }
  private get selectedReportedRows(): any {
    return this.selectedRows.filter(item => item.reported)
  }
  private get ratio(): any {
    return this.$store.state.ratio
  }
  private get userAuths(): any {
    return this.$store.state.userAuths
  }
  private get reportType(): any {
    return this.$route.params.reportType
  }
  private get periodId(): any {
    return this.reportType === 'term' ? this.filters.periodId?.[1] : this.filters.periodId
  }

  private calcDis(record): any {
    return record.sent
    // || (this.commentsCtrl.courseTeacher && record.ctCommentCount === 0 && record.courseTeacherCount !== 0)
    // || (this.commentsCtrl.headteacher && record.htCommentCount === 0 && record.headTeacherCount !== 0)
    // || (this.commentsCtrl.tutor && record.tutorCommentCount === 0 && record.tutorCount !== 0)
    // || (this.commentsCtrl.lbTeacher && record.lbCommentCount === 0 && record.lbTeacherCount !== 0)
  }

  private calcReported(record): any {
    return record.reported
  }

  private clearSelection(): void {
    this.selectedRows = []
  }

  private selectAll(): void {
    this.clearSelection()
    this.selectedRows = cloneDeep(this.allStudentsIds)
  }

  private changeYear(): void {
    this.schoolYearType = this.schoolYearList.find(
      item => item.key === this.filters.schoolYearId
    ).value
    this.$nextTick(() => {
      this.data = []
      this.getPeriodDropDown()
    })
  }

  private created(): void {
    this.getDropDownInfo()
  }

  private downloadReport(record) {
    this.$set(record, 'downloading', true)
    const domain = process.env.VUE_APP_DOMAIN
    const printUrl = process.env.VUE_APP_PDF_PRINT_URL
    let token = getToken()
    let schoolInfo = getSchoolInfo()
    let targetUrl = `${domain}exportReport/${token}/${schoolInfo.schoolId}/${record.studentId}/${
      this.periodId
    }/${this.reportType}/${this.campusCode[this.campusType]}/${moment().valueOf()}/${
      this.schoolYearType
    }`
    let url = `${printUrl}api/render/?url=${targetUrl}&pdf.printBackground=true&pdf.margin.left=0mm&pdf.margin.right=0mm&pdf.margin.top=4mm&waitFor=${'.loaded'}`
    // window.open(targetUrl)

    let campus: any = {}
    let period: any = {}
    if (this.reportType === 'term') {
      campus = this.periods.find(item => item.key === this.filters.periodId[0])
      period = campus.subOptions.find(item => item.key === this.filters.periodId[1])
    } else {
      period = this.periods.find(item => item.key === this.filters.periodId)
    }

    const reportName = period.value + '报告单'
    const reportEnName = 'Report for ' + period.enValue + '.pdf'
    const name = record.fullName + '_' + reportName + '_' + reportEnName
    saveAs(url, `${name}`, record.studentId)
    EventBus.$once(`onload_${record.studentId}`, () => {
      this.$set(record, 'downloading', false)
    })
  }

  private batchDownload(): void {
    if (this.selectedRows.length === 0) {
      this.$message.error(this.$tc('tips.selectStudents'))
      return
    }
    this.downloading = true
    let campus: any = {}
    let period: any = {}
    if (this.reportType === 'term') {
      campus = this.periods.find(item => item.key === this.filters.periodId[0])
      period = campus.subOptions.find(item => item.key === this.filters.periodId[1])
    } else {
      period = this.periods.find(item => item.key === this.filters.periodId)
    }
    const reportName = period.value + '报告单'
    const reportEnName = 'Report for ' + period.enValue + '.pdf'
    const name =
      this.reportType === 'term'
        ? `${this.locale === 'zh' ? campus.value : campus.enValue}_${
            this.locale === 'zh' ? period.value : period.enValue
          }_Report.zip`
        : `${this.locale === 'zh' ? period.value : period.enValue}_Report.zip`

    const domain = process.env.VUE_APP_DOMAIN
    const printUrl = process.env.VUE_APP_PDF_PRINT_URL
    const token = getToken()
    const schoolInfo = getSchoolInfo()
    let arr = [] as any
    this.selectedRows.forEach(item => {
      if (!item.reported) return
      let targetUrl = `${domain}exportReport/${token}/${schoolInfo.schoolId}/${item.studentId}/${
        this.periodId
      }/${this.reportType}/${this.campusCode[this.campusType]}/${moment().valueOf()}/${
        this.schoolYearType
      }`
      let url = `${printUrl}api/render/?url=${targetUrl}&pdf.printBackground=true&pdf.margin.left=0mm&pdf.margin.right=0mm&pdf.margin.top=4mm&waitFor=${'.loaded'}`
      arr.push({
        url,
        name: item.fullName + '_' + reportName + '_' + reportEnName + '.pdf',
      })
    })
    // if(!arr.length){
    //   this.$message.error(this.$tc('tips.selectStudents'))
    //   this.unavailableStudent = true
    //   return
    // }
    handleBatchDownload(arr, name, () => {
      this.downloading = false
    })
  }

  private checkAvailable(): void {
    let arr = [] as any
    this.selectedRows.forEach(item => {
      if (item.gradeCount > item.gradedCount) return
      arr.push(item)
    })
    this.unavailableStudent = arr.length === 0
  }

  private editComment(record, index): void {
    this.isLast = this.validateIsLast(index)
    this.editStudent = record
    this.$set(this.editStudent, 'index', index)
    this.editVisible = true
  }

  private async exportComments(): Promise<void> {
    this.exportLoading = true
    const typeCode = {
      homeroom: 1,
      subjectClass: 2,
      advisory: 3,
      CCAClass: 4,
    }
    GradeController.getCommentExport(this.periodId)
      .then(async res => {
        const students = res.data.items.map(item => ({
          ...item,
          headTeacherComments: item.headTeacherComments
            .filter(teacher => teacher.comment)
            .map(teacher => `${teacher.teacherName}：${teacher.comment.replace(/\n/g, ' ')}`)
            .join('\n'),
          advisorComments: item.tutorComments
            .filter(teacher => teacher.comment)
            .map(teacher => `${teacher.teacherName}：${teacher.comment}`)
            .join('\n'),
          subjectTeacherComments: item.courseComments
            .filter(teacher => teacher.comment)
            .map(teacher => `${teacher.teacherName}：${teacher.comment}`)
            .join('\n'),
          // headTeachers: item.headTeacherComments.map(teacher => teacher.teacherName).join('、'),
          // advisor: item.tutorComments.map(teacher => teacher.teacherName).join('、'),
          // subjectTeacher: item.courseComments.map(teacher => teacher.teacherName).join('、'),
          gradeAssessment: this.campusType !== '1231' ? '--' : item.primaryGrade,
        }))
        const auth = [
          'subjectTeacherComments',
          'headTeacherComments',
          'advisorComments',
          'courseTeachers',
          'tutorTeachers',
          'headTeachers',
        ]
        const excelColumns = this.excelColumns.filter(
          item =>
            !auth.includes(item.dataIndex) ||
            (['subjectTeacherComments', 'courseTeachers'].includes(item.dataIndex) &&
              res.data.courseTeacherComment) ||
            (['headTeacherComments', 'headTeachers'].includes(item.dataIndex) &&
              res.data.headTeacherComment) ||
            (['advisorComments', 'tutorTeachers'].includes(item.dataIndex) && res.data.tutorComment)
        )
        const file = exportXlsx(
          excelColumns.concat(
            res.data.gradeTitle
              ? [
                  {
                    dataIndex: 'levelOfMastery',
                    title: res.data.gradeTitle,
                    width: 20,
                  },
                ]
              : []
          ),
          students
        )
        const ws = file.getWorksheet(1)

        ws.eachRow(row => {
          row.height = 30
          row.eachCell(cell => {
            cell.border = {
              top: { style: 'thin', color: { argb: 'FF000000' } },
              left: { style: 'thin', color: { argb: 'FF000000' } },
              bottom: { style: 'thin', color: { argb: 'FF000000' } },
              right: { style: 'thin', color: { argb: 'FF000000' } },
            }
            cell.style.alignment = { vertical: 'middle', horizontal: 'center' }
          })
        })
        const current = moment().format('YYYY.MM.DD')
        await writeFile(file, `Comment Report_${current}.xlsx`)
      })
      .catch(err => console.log(err))
      .finally(() => (this.exportLoading = false))
  }

  private async exportYearlyComments(): Promise<void> {
    this.exportYearlyLoading = true
    const typeCode = {
      homeroom: 1,
      subjectClass: 2,
      advisory: 3,
      CCAClass: 4,
    }
    GradeController.getAnnualReportExport(this.periodId)
      .then(async res => {
        const students = res.data.items.map(item => {
          const semesters = item.semesterGrades

          let result = {
            ...item,
            advisorComments: item.tutorComments
              .filter(teacher => teacher.comment)
              .map(teacher => `${teacher.teacherName}：${teacher.comment}`)
              .join('\n'),
            tutorTeachers: item.tutorComments.map(teacher => teacher.teacherName).join('、'),
          }

          for (let key in semesters) {
            result[`${key}_score`] = semesters[`${key}`].score
            result[`${key}_grade`] = semesters[`${key}`].grade
          }
          return result
        })

        const auth = [
          'subjectTeacherComments',
          'headTeacherComments',
          'advisorComments',
          'courseTeachers',
          'tutorTeachers',
          'headTeachers',
        ]

        const excelColumns = this.excelColumns.filter(
          item =>
            !auth.includes(item.dataIndex) ||
            ['subjectTeacherComments', 'courseTeachers'].includes(item.dataIndex) ||
            ['headTeachers'].includes(item.dataIndex) ||
            (['advisorComments', 'tutorTeachers'].includes(item.dataIndex) && res.data.tutorComment)
        )

        const yearlyExcelColumns = {
          '1232': res.data.semesters
            .map(item => [
              {
                dataIndex: `${item.semesterId}_score`,
                title: item.semesterType,
                width: 20,
              },
              {
                dataIndex: `${item.semesterId}_grade`,
                title: `${item.semesterType} Mastery`,
                width: 20,
              },
            ])
            .flat()
            .concat([
              {
                dataIndex: 'finalScore',
                title: 'Overall Grade',
                width: 20,
              },
              {
                dataIndex: 'finalMastery',
                title: 'Overall Mastery',
                width: 20,
              },
              {
                dataIndex: 'absentNum',
                title: 'Total Abs',
                width: 20,
              },
            ]),
          '1233': res.data.semesters
            .map(item => ({
              dataIndex: `${item.semesterId}_grade`,
              title: item.semesterType,
              width: 20,
            }))
            .concat([
              {
                dataIndex: 'meGrades',
                title: 'ME',
                width: 20,
              },
              {
                dataIndex: 'feGrades',
                title: 'FE',
                width: 20,
              },
              {
                dataIndex: 'finalMastery',
                title: 'Overall',
                width: 20,
              },
              {
                dataIndex: 'absentNum',
                title: 'Abs.',
                width: 10,
              },
              {
                dataIndex: 'credits',
                title: 'Credits',
                width: 10,
              },
              {
                dataIndex: 'annualGPA',
                title: 'Year GPA',
                width: 10,
              },
              {
                dataIndex: 'cumulativeGPA',
                title: 'Cumulative GPA',
                width: 10,
              },
            ]),
        }
        const file = exportXlsx(excelColumns.concat(yearlyExcelColumns[this.campusType]), students)
        const ws = file.getWorksheet(1)

        ws.eachRow(row => {
          row.height = 30
          row.eachCell(cell => {
            cell.border = {
              top: { style: 'thin', color: { argb: 'FF000000' } },
              left: { style: 'thin', color: { argb: 'FF000000' } },
              bottom: { style: 'thin', color: { argb: 'FF000000' } },
              right: { style: 'thin', color: { argb: 'FF000000' } },
            }
            cell.style.alignment = { vertical: 'middle', horizontal: 'center' }
          })
        })
        const current = moment().format('YYYY.MM.DD')
        await writeFile(file, `Year Report Comment Report_${current}.xlsx`)
      })
      .catch(err => console.log(err))
      .finally(() => (this.exportYearlyLoading = false))
  }

  private filterData(inputValue, path) {
    return path.some(option => {
      return option[this.locale === 'zh' ? 'value' : 'enValue']
        ?.toLowerCase()
        .includes(inputValue?.toLowerCase())
    })
  }

  private fetchData = debounce(() => {
    this.getData()
  }, 500)

  private getData(page = { current: 1, pageSize: this.pagination.defaultPageSize }): void {
    if (!this.periodId) return

    this.loading = true
    this.data = []
    let reqId = ++this.reqId
    this.pagination.current = page.current
    const schoolYearId = this.filters.schoolYearId
    const gradePeriodId = this.periodId
    const classId = this.filters.homeroomId[1]
    const houseId = this.filters.advisoryId
    const name = this.filters.name?.trim()

    this.getStudentIdList(schoolYearId, gradePeriodId, classId, houseId, name)
    // this.selectedRows = []

    GradeController.getGradeReportSummaries(
      page.current,
      page.pageSize,
      schoolYearId,
      gradePeriodId,
      classId,
      houseId,
      name
    )
      .then(res => {
        if (reqId !== this.reqId) return
        this.data = res.data.items
        this.pagination.total = res.data.totalItem
        this.pagination.current = page.current
        toPage(page, this.data, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => console.log(err))
      .finally(() => {
        if (reqId !== this.reqId) return
        this.loading = false
      })
  }

  private getStudentIdList(schoolYearId, gradePeriodId, classId, houseId, name): void {
    GradeController.getGradeReportStudentIds(schoolYearId, gradePeriodId, classId, houseId, name)
      .then(res => {
        this.allStudentsIds = res.data
      })
      .catch(err => console.error(err))
  }

  private getDropDownInfo(): void {
    Promise.all([
      // DropDownController.getClassList(),
      DropDownController.getHouseGroupList(),
      DropDownController.getSchoolYearRuleList(),
    ])
      .then(res => {
        // this.homerooms = clearEmptyArray(res[0].data)
        this.advisories = res[0].data
        this.schoolYearList = res[1].data
        this.filters.schoolYearId = (res[1].data[0] || {}).key
        this.schoolYearType = (res[1].data[0] || {}).value
        if (this.filters.schoolYearId) {
          this.getPeriodDropDown()
        }
      })
      .catch(err => console.log(err))
  }

  private getPeriodDropDown(): void {
    this.schedule = 0
    this.result = false

    if (!this.filters.schoolYearId) return
    operations[this.reportType + 'Period'](this.filters.schoolYearId).then(res => {
      this.homerooms = []
      if (this.reportType === 'term') {
        this.periods = clearEmptyArray(
          res.data
            .map(item => ({
              ...item,
              subOptions: item.subOptions.filter(period => period.extraValue.status !== 'DNS'),
            }))
            .filter(item => item.subOptions.length)
        )
        this.filters.periodId = this.periods[0]
          ? [this.periods[0]?.key, this.periods[0]?.subOptions[0]?.key]
          : []
        this.campusType = this.periods[0]?.extraValue
        this.periodStatus = this.periods[0]?.subOptions[0]?.extraValue?.status
        this.periodReport = this.periods[0]?.subOptions[0]?.extraValue?.reported
        this.commentsCtrl = {
          courseTeacher: this.periods[0]?.subOptions[0]?.extraValue?.courseTeacherComment,
          headteacher: this.periods[0]?.subOptions[0]?.extraValue?.headTeacherComment,
          tutor: this.periods[0]?.subOptions[0]?.extraValue?.tutorComment,
          lbTeacher: this.periods[0]?.subOptions[0]?.extraValue?.lifeBlockComment,
        }
        this.getHomeroom(this.filters.periodId?.[1])
      } else {
        this.periods = res.data
          .filter(item => item.status !== 'DNS')
          .map(item => ({
            ...item,
            key: item.gradePeriodId,
            value: item.name,
            enValue: item.enName,
          }))
        this.filters.periodId = this.periods[0]?.gradePeriodId
        this.campusType = this.periods[0]?.type
        this.periodStatus = this.periods[0]?.status
        // 学年报告是否生成过报告
        this.periodReport = this.periods[0]?.reported

        this.commentsCtrl = {
          courseTeacher: this.periods[0]?.courseTeacherComment,
          headteacher: this.periods[0]?.headTeacherComment,
          tutor: this.periods[0]?.tutorComment,
          lbTeacher: this.periods[0]?.lifeBlockComment,
        }
        this.getHomeroom(this.filters.periodId)
      }
      this.getData()
      if (this.periodReport) {
        this.generateReporting = true
        this.polling()
      } else {
        this.generateReporting = false
        clearInterval(this.timer)
      }
    })
  }

  private preView(record): void {
    this.$set(record, 'previewing', true)
    this.previewId = record.studentId
    const type =
      this.reportType === 'term'
        ? this.campusCode[this.campusType]
        : this.campusCode[this.campusType] + 'Year'
    operations[type](this.periodId, record.studentId)
      .then(res => {
        this.reportData = res.data
        this.previewVisible = true
      })
      .catch(err => console.log(err))
      .finally(() => {
        this.$set(record, 'previewing', false)
        this.previewId = -1
      })
  }

  private getHomeroom(gradePeriodId): void {
    if (!gradePeriodId) return
    this.$set(this.filters, 'homeroomId', [])
    DropDownController.getClassByPeriod(gradePeriodId)
      .then(res => {
        this.homerooms = clearEmptyArray(res.data)
      })
      .catch(err => {
        console.error(err)
      })
  }

  private handleTableChange(pagination): void {
    this.pagination.current = pagination.current
    this.pagination.pageSize = pagination.pageSize
    this.getData(pagination)
  }

  private onSelect(record, selected, selectedRows, nativeEvent): void {
    const { studentId, fullName, gradeCount, gradedCount, reported } = record

    selected
      ? this.selectedRows.push({
          studentId,
          fullName,
          gradeCount,
          gradedCount,
          reported,
        })
      : this.selectedRows.splice(
          this.selectedRows.findIndex(item => item.studentId === record.studentId),
          1
        )
    this.checkAvailable()
  }

  private onSelectAll(selected, selectedRows, changeRows): void {
    if (selected) {
      changeRows.forEach(item => {
        const { studentId, fullName, gradeCount, gradedCount, reported } = item
        this.selectedRows.push({ studentId, fullName, gradeCount, gradedCount, reported })
      })
    } else {
      changeRows.forEach(item => {
        this.selectedRows.splice(
          this.selectedRows.findIndex(row => row.studentId === item.studentId),
          1
        )
      })
    }
    this.checkAvailable()
  }

  private onPeriodChange(id): any {
    this.schedule = 0
    this.result = false
    this.generateReporting = false
    clearInterval(this.timer)

    this.tipsVisible = true
    if (this.reportType === 'term') {
      this.periods.forEach(item => {
        if (item.key !== id[0]) return
        this.campusType = item.extraValue
        item.subOptions.forEach(it => {
          if (it.key !== id[1]) return
          this.periodStatus = it.extraValue
          this.periodStatus = it.extraValue?.status
          this.periodReport = it.extraValue?.reported
          this.commentsCtrl = {
            courseTeacher: it.extraValue?.courseTeacherComment,
            headteacher: it.extraValue?.headTeacherComment,
            tutor: it.extraValue?.tutorComment,
            lbTeacher: it.extraValue?.lifeBlockComment,
          }
        })
      })
      this.getHomeroom(id[1])
    } else {
      const period = this.periods.find(item => item.gradePeriodId === id)
      if (!period) return
      this.campusType = period.type
      this.periodStatus = period.status
      // 学年报告是否生成过报告
      this.periodReport = period?.reported
      this.commentsCtrl = {
        courseTeacher: period.courseTeacherComment,
        headteacher: period.headTeacherComment,
        tutor: period.tutorComment,
        lbTeacher: period.lifeBlockComment,
      }
      this.getHomeroom(this.filters.periodId)
    }
    this.$nextTick(() => {
      this.getData()
      // 生成过报告 轮询进度
      if (this.periodReport) {
        this.generateReporting = true
        this.polling()
      }
    })
  }

  private refresh(): void {
    this.getData(this.pagination)
    this.selectedRows = []
  }

  private sendReport(record) {
    this.$set(record, 'sending', true)
    GradeController.sendGradeReport(record.studentId, this.periodId)
      .then(res => {
        if (res.data.status === 200) {
          this.$message.success(this.$tc('tips.sendSuccess'))
          return
        }
        this.$message.error(this.$tc(`errorCode.${res.data.status}`))
      })
      .catch(err => console.log(err))
      .finally(() => {
        this.$set(record, 'sending', false)
        this.refresh()
      })
  }

  private batchSend(): void {
    if (this.selectedRows.length === 0) {
      this.$message.error(this.$tc('tips.selectStudents'))
      return
    }
    let arr = [] as any
    this.selectedRows.forEach(item => {
      if (item.status === 'sent') return
      arr.push(item.studentId)
    })
    if (!arr.length) {
      this.$message.error(this.$tc('tips.selectAssessStudent'))
      return
    }
    this.$confirm({
      title: this.$t('grading.report.sendConfirm'),
      onOk: () => {
        this.sending = true
        GradeController.sendBatch(arr, this.periodId)
          .then(res => {
            if (!res.data?.length) {
              this.$message.success(this.$tc('tips.sendSuccess'))
              return
            }
            this.resultVis = true
            this.sendResult = res.data.map(item => {
              return {
                name: item.student,
                reason: this.$t(`errorCode.${item.status}`),
              }
            })
          })
          .catch(err => console.log(err))
          .finally(() => {
            this.sending = false
            this.refresh()
          })
      },
    })
  }

  private updateMark(params): void {
    ;(this.editStudent.tutorComment = params.tutorComment),
      (this.editStudent.headTeacherComment = params.headTeacherComment)
    if (params.next) {
      let index = this.editStudent.index + 1
      while (
        this.data[index].status === 'published' ||
        this.data[index].status === 'notGenerated'
      ) {
        index++
      }
      this.isLast = this.validateIsLast(index)
      this.editStudent = this.data[index]
      this.$set(this.editStudent, 'index', index)
    }
  }

  private validateIsLast(index): boolean {
    do {
      index++
      if (index === this.data.length) {
        return true
      }
    } while (this.data[index].status === 'published' || this.data[index].status === 'notGenerated')
    return false
  }

  private showProgressDetail(record, type): void {
    this.progressVis = true
    let campus: any = {}
    let period: any = {}
    if (this.reportType === 'term') {
      campus = this.periods.find(item => item.key === this.filters.periodId[0])
      period = campus.subOptions.find(item => item.key === this.filters.periodId[1])
    } else {
      period = this.periods.find(item => item.key === this.filters.periodId)
    }
    this.progressData = {
      studentId: record.studentId,
      gradePeriodId: this.periodId,
      student: record.fullName,
      type,
      period: period,
    }
  }

  private generateReport(flag): void {
    GradeController.generateReport(flag, this.periodId).then(res => {
      this.schedule = Math.round(res.data.schedule * 100)
      this.result = res.data.result
      if (this.result) {
        this.generateReporting = false
        clearInterval(this.timer)
        this.refresh()
      }
    })

    if (!flag) {
      this.generateReporting = true
      this.periodReport = true
      this.polling()

      const selectedPeriod = this.periods
        .find(item => item.key === this.filters.periodId[0])
        .subOptions.find(item => item.key === this.filters.periodId[1])

      this.$set(selectedPeriod.extraValue, 'reported', true)
    }
  }

  private polling() {
    this.generateReport(true)
    this.timer = setInterval(() => {
      setTimeout(() => {
        this.generateReport(true)
      }, 0)
    }, 5 * 1000)

    this.$once('hook:beforeDestroy', () => {
      clearInterval(this.timer)
    })
  }

  @Watch('reportType', { immediate: true })
  public onReportTypeChange(val): void {
    clearInterval(this.timer)
  }
}
