

























































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import FlexTooltip from '@/components/FlexTooltip.vue'
import debounce from 'lodash/debounce'
import filterGroup from '@/components/filterGroup'
import { toPage, guid, judgeVnode } from '@/utils/utils'
import { createPagination } from '@/constant/constant'
import { DropDownController, AcademicLevelController } from '@/services/request.service'
import ChangeLabel from './components/ChangeLabel.vue'
import EditRecord from './components/EditRecord.vue'
import Statement from './components/Statement.vue'
import moment from 'moment'
import { i18n } from '@/i18n/i18n'

Component.registerHooks(['beforeRouteLeave'])

@Component({
  components: {
    FlexTooltip,
    filterGroup,
    ChangeLabel,
    EditRecord,
    Statement,
  },
})
export default class AcademicStanding extends Vue {
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private dataEmptyFlag: boolean = true

  private loading: boolean = false
  private exportAcademicLoading: boolean = false
  private exportCourseLoading: boolean = false
  private filter: any = {
    studentName: undefined,
    studentNum: undefined,
    houseGroupId: undefined,
    sectionIds: 0,
    semesterIds: [],
    modified: undefined,
    status: undefined,
    addStatus: undefined,
    updatable: undefined,
  }
  private data: Array<any> = []
  private houses: Array<any> = []
  private seniorList: Array<any> = []
  private semesterIds: any = []
  private semesters: Array<any> = []

  private tagList: any = [
    {
      key: 'noLabel',
      value: '无状态',
      enValue: 'No Status',
      borderColor: 'white',
      color: 'white',
    },
    {
      key: 'standing',
      value: '良好',
      enValue: 'Good Standing',
      borderColor: 'rgba(82, 197, 26, 1)',
      color: 'rgba(82, 197, 26, 0.25)',
    },
    {
      key: 'warning',
      value: '学术警告',
      enValue: 'Academic Warning',
      borderColor: 'rgba(249, 219, 19, 1)',
      color: 'rgba(249, 219, 19, 0.25)',
    },
    {
      key: 'probation',
      value: '学术察看',
      enValue: 'Academic Probation',
      borderColor: 'rgba(250, 140, 20, 1)',
      color: 'rgba(250, 140, 20, 0.25)',
    },
  ]

  private extraTag: any = [
    {
      key: 'noLabel',
      value: '无状态',
      enValue: 'No Status',
      color: 'white',
      borderColor: 'white',
    },
    {
      key: 'review',
      value: '学术审查',
      enValue: 'Academic Review',
      borderColor: 'rgba(245, 32, 45, 1)',
      color: 'rgba(245, 32, 45, 0.25)',
    },
  ]

  private get allTags(): any {
    return this.tagList.concat(this.extraTag)
  }

  private options: Array<any> = []
  // private currentTag: string = ''
  private record: any = {}
  private editType: string = ''
  private editVisibility: boolean = false
  private statement: any = {}
  private detailVisiblity: boolean = false
  private recordVisibility: boolean = false
  private statementVisibility: boolean = false

  private moment = moment

  private get locale(): string {
    return this.$store.state.locale
  }

  private get isZh(): boolean {
    return this.$store.state.locale === 'zh'
  }

  private get ratio(): any {
    return this.$store.state.ratio || 1
  }

  private get isChildRoute(): boolean {
    return this.$route.name !== 'academicStanding'
  }

  private get operationAuths(): any {
    return this.$store.state.operationAuths
  }

  private get columns(): Array<any> {
    return [
      {
        key: 'studentName',
        dataIndex: 'studentName',
        title: this.$t('common.personName'),
        scopedSlots: { customRender: 'name' },
        // width: 270 * this.ratio,
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
          }
        },
      },
      {
        key: 'studentNum',
        dataIndex: 'studentNum',
        width: 120 * this.ratio,
        title: this.$t('common.studentId'),
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
          }
        },
      },
      {
        key: 'section',
        dataIndex: 'section',
        width: 70 * this.ratio,
        title: this.$t('common.grade'),
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
          }
        },
      },
      {
        key: 'advisory',
        dataIndex: 'advisory',
        // width: 200 * this.ratio,
        ellipsis: true,
        title: this.$t('myClass.house'),
        scopedSlots: { customRender: 'advisory' },
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
          }
        },
      },
      {
        key: 'schoolYear',
        dataIndex: 'schoolYear',
        width: 110 * this.ratio,
        title: this.$t('common.schoolYear'),
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
          }
        },
      },
      {
        key: 'semester',
        dataIndex: 'semesterFormat',
        width: 70 * this.ratio,
        title: this.$t('common.term'),
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
          }
        },
      },
      {
        key: 'status',
        dataIndex: 'status',
        width: 100,
        title: this.$t('common.status'),
        scopedSlots: { customRender: 'status' },
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
            class: 'status-cell',
          }
        },
      },
      {
        key: 'addStatus',
        dataIndex: 'addStatus',
        width: 100,
        title: this.$t('academic.additionalStatus'),
        scopedSlots: { customRender: 'status' },
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
            class: 'status-cell',
          }
        },
      },
      {
        key: 'modifiedRecord',
        width: 100 * this.ratio,
        title: this.$t('academic.modifiedRecord'),
        scopedSlots: { customRender: 'modifiedRecord' },
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
            class: 'status-cell',
          }
        },
      },
      {
        key: 'operations',
        width: 100,
        title: this.$t('common.operations'),
        scopedSlots: { customRender: 'operations' },
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
          }
        },
      },
    ]
  }

  private get formatSemesters(): any {
    return this.semesters.map(item => {
      return {
        children: item.subOptions.map(child => ({
          key: child.key,
          title: this.locale === 'zh' ? child.value : child.enValue || child.value,
          value: child.key,
        })),
        title: `${item.value}`,
        key: guid() as any,
        value: item.key,
      }
    })
  }

  private calcBg(text): any {
    return this.allTags.find(item => item.key === text)
  }

  private reset(): void {
    this.filter = {
      studentName: undefined,
      studentNum: undefined,
      houseGroupId: undefined,
      sectionIds: 0,
      semesterIds: [],
      modified: undefined,
      status: undefined,
      addStatus: undefined,
      updatable: undefined,
    }
    this.semesterIds = []
    this.$set(this.pagination, 'current', 1)
    this.refresh()
  }

  private onSemesterChange(value, label, extra) {
    const isVNode = judgeVnode(extra.triggerNode)
    this.filter.semesterIds = (extra.allCheckedNodes || [])
      .map(item => {
        if (!isVNode) {
          if (item.children) {
            return item.children.map(item => item.node.key)
          } else {
            return item.node.key
          }
        } else {
          if (item.componentOptions.children.length) {
            return item.componentOptions.children.map(item => item.data.key)
          } else {
            return item.data.key
          }
        }
      })
      .flat()
    this.fetchData()
  }

  private created(): void {
    this.getDropDownInfo()
  }

  private getDropDownInfo(): void {
    Promise.all([
      DropDownController.getHouseGroupListAll(),
      DropDownController.getSectionsByType('1233'),
      DropDownController.getSemesterCascade(),
    ])
      .then(res => {
        this.houses = res[0].data
        this.seniorList = res[1].data
        // this.semesterIds = res[2].data
        this.semesters = res[2].data
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => this.getData())
  }

  private refresh(): void {
    this.getData(this.pagination)
  }

  private fetchData = debounce(() => {
    this.getData()
  }, 500)

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(pagination)
  }

  private getData(page = { current: 1, pageSize: this.pagination.defaultPageSize }): void {
    this.loading = true
    const {
      studentName,
      studentNum,
      houseGroupId,
      sectionIds,
      semesterIds,
      modified,
      status,
      addStatus,
      updatable,
    } = this.filter
    const params = {
      pageCurrent: page.current,
      pageSize: page.pageSize,
      studentName: studentName?.trim() || undefined,
      studentNum: studentNum?.trim() || undefined,
      houseGroupId: houseGroupId || undefined,
      sectionIds: sectionIds || undefined,
      semesterIds: semesterIds || undefined,
      modified: modified === undefined ? undefined : Boolean(modified),
      // status,
      // addStatus: addStatus ? addStatus : undefined,
      updatable: updatable === undefined ? undefined : Boolean(updatable),
    }
    AcademicLevelController.getAcademicLevel(
      params.pageCurrent,
      params.pageSize,
      undefined,
      params.studentName,
      params.studentNum,
      params.houseGroupId,
      params.sectionIds,
      params.semesterIds,
      params.modified,
      status,
      addStatus,
      params.updatable
      // params.addStatus
    )
      .then(res => {
        this.data = res.data.items.map(item => {
          return {
            ...item,
            addStatus: item.addStatus ? item.addStatus : 'noLabel',
            addStatusCal: item.addStatusCal ? item.addStatusCal : 'noLabel',
            semesterFormat: `T${item.semester.substr(-1)}`,
          }
        })
        this.pagination.total = res.data.totalItem
        this.pagination.current = page.current
        toPage(page, this.data, page => {
          this.pagination.current = page
          this.refresh()
        })
        if (!this.data.length) {
          this.dataEmptyFlag = true
        } else {
          this.dataEmptyFlag = false
        }
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.loading = false))
  }

  private showStatement(record, col): void {
    // if(!record[col.key]) return
    this.editType = col.key
    this.options = col.key === 'status' ? cloneDeep(this.tagList) : cloneDeep(this.extraTag)
    this.record = record
    this.statementVisibility = true
  }

  private showEditModal(record, col): void {
    this.editType = col.key
    this.options = col.key === 'status' ? cloneDeep(this.tagList) : cloneDeep(this.extraTag)
    this.record = record
    this.editVisibility = true
  }

  // private changeTag(label): void {
  //   AcademicLevelController.updateAcademicLevel()
  // }

  private showRecordModal(record): void {
    if (!record.hasEditLog) return
    AcademicLevelController.getStatusDetail(record.semesterId, record.studentId).then(res => {
      this.record = record
      this.statement = res.data
      this.recordVisibility = true
    })
  }

  private changeLabel(res): void {
    this.refresh()
  }

  private updateReportStatus(record): void {
    AcademicLevelController.updateReportStatus(record.semesterId, record.studentId)
      .then(res => {
        this.$message.success(this.$tc('common.saveSuccess'))
        record.upDatable = false
      })
      .catch(err => console.error(err))
  }

  private exportAcademic(): void {
    this.exportAcademicLoading = true
    const {
      studentName,
      studentNum,
      houseGroupId,
      sectionIds,
      semesterIds,
      modified,
      status,
      addStatus,
      updatable,
    } = this.filter
    const params = {
      studentName: studentName?.trim() || undefined,
      studentNum: studentNum?.trim() || undefined,
      houseGroupId: houseGroupId || undefined,
      sectionIds: sectionIds || undefined,
      semesterIds: semesterIds || undefined,
      modified: modified === undefined ? undefined : Boolean(modified),
      // status,
      // addStatus: addStatus ? addStatus : undefined,
      updatable: updatable === undefined ? undefined : Boolean(updatable),
    }
    AcademicLevelController.getAcademicLevelExport(
      undefined,
      params.studentName,
      params.studentNum,
      params.houseGroupId,
      params.sectionIds,
      params.semesterIds,
      params.modified,
      status,
      addStatus,
      params.updatable
    )
      .then(res => {
        const students = res.data.map(item => ({
          ...item,
          editRecord: item.editLogs
            .map(it =>
              this.$t('academic.timeEditDetail', {
                time: moment(it.modifyTime).format('YYYY.MM.DD'),
                old: it.from,
                new: it.to,
              })
            )
            .join(';'),
          formatStatus: item.status ? this.$t(`allLabel.${item.status}`) : ' ',
          formatAddStatus: item.addStatus ? this.$t(`allLabel.${item.addStatus}`) : '',
          formatAverage: item.average ? item.average : '',
          notStandingCourses: item.notStandingCourses
            .map(it => it.courseName + it.point)
            .join('、'),
        }))
        const dataIndexList = [
          'lastName',
          'enName',
          'firstName',
          'name',
          'studentNum',
          'sectionName',
          'advisory',
          'advisor',
          'schoolYear',
          'semesterName',

          'formatStatus',
          'formatAddStatus',

          'editRecord',

          'notStandingCourses',
          // 'probationCourses',
          'formatAverage',
        ]
        const title = [
          this.$t('common.surname'),
          this.$t('common.enName'),
          this.$t('common.givenName'),
          this.$t('common.cnName'),
          this.$t('common.studentId'),
          this.$t('common.grade'),
          this.$t('common.advisory'),
          this.$t('common.tutor'),
          this.$t('common.schoolYear'),
          this.$t('common.term'),
          this.$t('common.status'),
          this.$t('academic.additionalStatus'),
          this.$t('academic.editRecord'),
          this.$t('academic.newWarningCourse'),
          // this.$t('academic.probationCourses'),
          this.$t('common.average'),
        ]

        let mainStr: any = []
        mainStr.push(title.join(',') + '\n')
        for (let i = 0; i < students.length; i++) {
          const temp: any = []
          for (let j = 0; j < dataIndexList.length; j++) {
            let strItem = students[i][dataIndexList[j]]
            strItem = `"${strItem}"`
            temp.push(strItem + '')
          }
          mainStr.push(temp.join(',') + '\n')
        }

        const result = mainStr.join('')
        const url = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(result)
        const link = document.createElement('a') //通过创建a标签实现
        link.href = url
        link.download = `Academic_Standing.csv`
        document.body.appendChild(link)
        link.click()

        this.exportAcademicLoading = false
        document.body.removeChild(link)
      })
      .catch(err => {
        console.log(err)
        this.exportAcademicLoading = false
      })
  }

  private exportCourse(): void {
    this.exportCourseLoading = true
    const {
      studentName,
      studentNum,
      houseGroupId,
      sectionIds,
      semesterIds,
      modified,
      status,
      addStatus,
      updatable,
    } = this.filter
    const params = {
      studentName: studentName?.trim() || undefined,
      studentNum: studentNum?.trim() || undefined,
      houseGroupId: houseGroupId || undefined,
      sectionIds: sectionIds || undefined,
      semesterIds: semesterIds || undefined,
      modified: modified === undefined ? undefined : Boolean(modified),
      // status,
      // addStatus: addStatus ? addStatus : undefined,
      updatable: updatable === undefined ? undefined : Boolean(updatable),
    }
    AcademicLevelController.getNotStandingAcademicLevelExport(
      undefined,
      params.studentName,
      params.studentNum,
      params.houseGroupId,
      params.sectionIds,
      params.semesterIds,
      params.modified,
      status,
      addStatus,
      params.updatable
    )
      .then(res => {
        const students = res.data
        let handleData: any = []
        for (let i = 0; i < students.length; i++) {
          const {
            notStandingCourses,
            editLogs,
            warningCourses,
            probationCourses,
            status,
            addStatus,
            updated,
            ...items
          } = students[i]
          for (let j = 0; j < notStandingCourses.length; j++) {
            handleData.push({
              ...items,
              course: notStandingCourses[j].courseName,
              point: notStandingCourses[j].point,
              formatStatus: this.$t(`academic.${status}`),
              formatAddStatus: addStatus ? this.$t(`academic.${addStatus}`) : '',
              formatUpdated: this.$t(`common.${updated}`),
            })
          }
        }

        const dataIndexList = [
          'lastName',
          'enName',
          'firstName',
          'name',
          'studentNum',
          'sectionName',
          'advisory',
          'advisor',
          'schoolYear',
          'semesterName',

          'formatStatus',
          'formatAddStatus',

          'average',

          'course',
          'point',
          'formatUpdated',
        ]

        const title = [
          this.$t('common.surname'),
          this.$t('common.enName'),
          this.$t('common.givenName'),
          this.$t('common.cnName'),
          this.$t('common.studentId'),
          this.$t('common.grade'),
          this.$t('common.advisory'),
          this.$t('common.tutor'),
          this.$t('common.schoolYear'),
          this.$t('common.term'),
          this.$t('common.status'),
          this.$t('academic.additionalStatus'),
          this.$t('academic.average'),
          this.$t('academic.belowStandard'),
          this.$t('academic.point'),
          this.$t('academic.isUnpdated'),
        ]

        let mainStr: any = []
        mainStr.push(title.join(',') + '\n')
        for (let i = 0; i < handleData.length; i++) {
          const temp: any = []
          for (let j = 0; j < dataIndexList.length; j++) {
            let strItem = handleData[i][dataIndexList[j]]
            strItem = `"${strItem}"`
            temp.push(strItem + '')
          }
          mainStr.push(temp.join(',') + '\n')
        }

        const result = mainStr.join('')
        const url = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(result)
        const link = document.createElement('a') //通过创建a标签实现
        link.href = url
        link.download = `Below_Standard_Courses.csv`
        document.body.appendChild(link)
        link.click()

        this.exportCourseLoading = false
        document.body.removeChild(link)
      })
      .catch(err => {
        console.log(err)
        this.exportCourseLoading = false
      })
  }

  private viewDetail(id): void {
    this.$router.push({ name: 'academicDetail', params: { studentId: id } }).catch(err => {})
  }

  private viewGradeBook(id): void {
    this.$router.push({ name: 'gradeBook', params: { studentId: id } }).catch(err => {})
  }

  @Watch('locale', { immediate: true })
  public onLocaleChange(value): void {
    this.$forceUpdate()
  }
}
