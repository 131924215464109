























import { Component, Vue, Watch } from 'vue-property-decorator'
import CommentViewer from './components/CommentViewer.vue'

@Component({
  components: {
    CommentViewer,
  },
})
export default class Comment extends Vue {
  private emptyAuth: any = false

  private get type(): any {
    return this.$route.params.commentType || ''
  }

  private get menuAuth(): any {
    return this.$store.state.userAuths || {}
  }

  @Watch('menuAuth', { deep: true, immediate: true })
  private onAuthChange(value): void {
    this.emptyAuth = false
    if (
      (this.type === 'headTeacher' && !(value.classTeacher || value.sectionTeacher)) ||
      (this.type === 'subject' && !(value.courseTeacher || value.courseManager)) ||
      (this.type === 'advisor' && !(value.houseGroupTeacher || value.houseTeacher)) ||
      (this.type === 'lifeBlock' && !value.ccaCourseTeacher) ||
      this.type === 'noAuth'
    ) {
      this.redirectTo(value)
    }
  }

  private redirectTo(value): void {
    this.emptyAuth = false
    if (value.classTeacher || value.sectionTeacher) {
      this.$router
        .replace({ name: 'comment', params: { commentType: 'headTeacher' } })
        .catch(err => {})
    } else if (value.houseGroupTeacher || value.houseTeacher) {
      this.$router.replace({ name: 'comment', params: { commentType: 'advisor' } }).catch(err => {})
    } else if (value.courseTeacher || value.courseManager) {
      this.$router.replace({ name: 'comment', params: { commentType: 'subject' } }).catch(err => {})
    } else if (value.ccaCourseTeacher) {
      this.$router
        .replace({ name: 'comment', params: { commentType: 'lifeBlock' } })
        .catch(err => {})
    } else {
      this.$router.replace({ name: 'comment', params: { commentType: 'noAuth' } }).catch(err => {})
      this.emptyAuth = true
    }
  }

  // @Watch('$route', { immediate: true })
  // private onRouteChange(to): void {
  //   if (to.name === 'comment' && !to.params.commentType) {
  //     this.$router.replace({ name: 'comment', params: { commentType: 'headTeacher' } }).catch(err => {})
  //   }
  // }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    if (to.name === 'comment' && !to.params.commentType) {
      this.redirectTo(this.menuAuth)
    } else if (to.params.commentType === 'noAuth') {
      this.emptyAuth = true
    }
  }

  private changeType({ item, key, keyPath }): void {
    this.$router.push({ name: 'comment', params: { commentType: key } }).catch(err => {})
  }
}
