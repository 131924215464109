import { render, staticRenderFns } from "./GradeBook.vue?vue&type=template&id=46c4ff10&scoped=true&"
import script from "./GradeBook.vue?vue&type=script&lang=ts&"
export * from "./GradeBook.vue?vue&type=script&lang=ts&"
import style0 from "./GradeBook.vue?vue&type=style&index=0&id=46c4ff10&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46c4ff10",
  null
  
)

export default component.exports