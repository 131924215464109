



















































import { Component, Vue, Watch } from 'vue-property-decorator'

import Grading from '@/views/common/studentDetail/components/Grading.vue'

import { StudentController } from '@/services/request.service'
import cloneDeep from 'lodash/cloneDeep'
import { getSchoolInfo, isBase64 } from '@/utils/utils'
import { fileUploader } from '@/services/qiniu.service'
import { detailMenu } from '@/constant/constant'

Component.registerHooks(['beforeRouteLeave'])

@Component({
  components: {
    Grading,
  },
})
export default class StudentDetail extends Vue {
  private newAvatar = ''
  private avatarVisible = false
  private saveLoading = false
  private canLeave = true
  private info: any = {}
  private schoolId: any = (getSchoolInfo() || {}).schoolId

  private get studentId(): number {
    return parseInt(this.$route.params.studentId, 10)
  }

  private created(): void {
    this.getStudentBrief()
  }

  private getStudentBrief(): void {
    StudentController.getStudentBriefInfo(this.studentId)
      .then(res => {
        this.info = res.data
        this.$store.commit('setCurriculumStudentInfo', this.info)
      })
      .catch(err => {
        console.error(err)
      })
  }

  // @Watch('studentId', { immediate: true })
  // private onIdChange(val) {
  //   if (!val) return
  //   this.getStudentBrief()
  // }
}
