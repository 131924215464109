






























































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import FilterGroup from '@/components/filterGroup'
import { clearEmptyArray } from '@/utils/utils'
import { debounce, round } from 'lodash'
import { DropDownController, GradeController, CourseController } from '@/services/request.service'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { filterData } from '@/utils/utils'

const operations = {
  headTeacher: function(gradePeriodId) {
    return DropDownController.getClassByPeriod(gradePeriodId)
  },
  advisor: function() {
    return DropDownController.getHouseGroupList()
  },
  subject: function(gradePeriodId) {
    return DropDownController.getCoursesCascadeByPeriod(gradePeriodId, false)
  },
  lifeBlock: function(schoolYearId) {
    return DropDownController.getCourseCcaBySchoolYear(schoolYearId)
  },
}

@Component({
  components: {
    FilterGroup,
    FlexTooltip,
  },
})
export default class CommentViewer extends Vue {
  @Prop() private readonly viewerType: any

  private filter = {
    schoolYearId: undefined,
    gradePeriodId: [],
    optionId: undefined,
  }
  private loading = false
  private data: Array<any> = []
  private schoolYears: Array<any> = []
  private studentMenuKey: any = []
  private gradePeriods: Array<any> = []
  private teachers: Array<any> = []
  private periodStatus = ''
  private drawerVisible = false
  private drawerInfo: any = {}
  private updateLoading = false
  private options: any = []
  private type2Code = {
    headTeacher: 'homeroom',
    subject: 'course',
    advisor: 'advisory',
    lifeBlock: 'lifeBlock',
    deputyHead: 'deputyHead',
  }
  private nextVisible: boolean = true
  private lastVisible: boolean = true
  private editStudent: any = {}
  private editTeacher: any = {}
  private reqId = 0
  private round = round
  private filterData = filterData

  private get locale(): string {
    return this.$store.state.locale
  }

  private get columns(): Array<any> {
    return [
      {
        key: 'name',
        title: this.$t('common.name'),
        ellipsis: true,
        scopedSlots: {
          customRender: 'name',
        },
        width: 220,
        fixed: 'left',
      },
      {
        key: 'code',
        title: this.$t('common.studentId'),
        width: 100,
        dataIndex: 'studentNum',
        fixed: 'left',
      },
      {
        key: 'gender',
        title: this.$t('personal.sex'),
        width: 80,
        scopedSlots: {
          customRender: 'gender',
        },
        fixed: 'left',
      },
      ['subject', 'lifeBlock'].includes(this.viewerType)
        ? {
            key: 'finalScore',
            title: this.$t('common.score'),
            width: 100,
            fixed: 'left',
            scopedSlots: { customRender: 'finalScore' },
            customCell: record => ({
              style: { background: !record.finalGrade && record.finalGrade !== 0 ? '' : '#f7ac21' },
            }),
          }
        : undefined,
      ...this.teachers.map(teacher => {
        return {
          key: teacher.teacherId,
          title: teacher.name,
          scopedSlots: {
            customRender: 'comment-' + teacher.teacherId,
          },
          ellipsis: true,
          customCell: (record, rowIndex) => {
            return {
              on: {
                click: () => {
                  if (
                    this.periodStatus === 'processing' &&
                    teacher.canEdit &&
                    this.operationAuths.includes('2076')
                  ) {
                    this.setDetail(record, teacher, rowIndex, false)
                  }
                },
              },
            }
          },
        }
      }),
    ].filter(item => item)
  }

  private get operationAuths(): any {
    return this.$store.state.operationAuths
  }

  private created(): void {
    this.getSchoolYears()
  }

  private getSchoolYears(): void {
    DropDownController.getSchoolYearRuleList()
      .then(res => {
        this.schoolYears = res.data
        if (res.data[0]) {
          this.$set(this.filter, 'schoolYearId', res.data[0].key)
          this.getGradePeriods(res.data[0].key)
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getGradePeriods(schoolYearId): void {
    this.gradePeriods = []
    this.filter.gradePeriodId = []
    const id = ++this.reqId
    // const type2Code = {
    //   headTeacher: '1001',
    //   subject: '1003',
    //   advisor: '1002',
    //   lifeBlock: '1004'
    // }
    DropDownController.getPeriodCascadeByType(schoolYearId, this.type2Code[this.viewerType])
      .then(res => {
        if (id !== this.reqId) return
        this.gradePeriods = clearEmptyArray(res.data)

        if (res.data[0]) {
          this.$set(this.filter, 'gradePeriodId', [res.data[0].key, res.data[0].subOptions[0].key])
          this.periodStatus = res.data[0].subOptions[0].extraValue
          if (this.viewerType === 'lifeBlock') {
            this.getCCAClasses(this.filter.schoolYearId, id)
          } else {
            this.getCourseClasses(res.data[0].subOptions[0].key, id)
          }
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getCourseClasses(gradePeriodId, id): void {
    this.options = []
    this.filter.optionId = undefined
    operations[this.viewerType](gradePeriodId).then(res => {
      if (id !== this.reqId) return
      if (this.viewerType === 'advisor') {
        this.options = res.data
        if (res.data[0]) {
          this.$set(this.filter, 'optionId', res.data[0]?.houseGroups[0]?.houseGroupId)
        }
      } else if (this.viewerType === 'lifeBlock') {
        this.options = res.data
        if (res.data[0]) {
          this.$set(this.filter, 'optionId', res.data[0].key)
        }
      } else {
        this.options = clearEmptyArray(res.data)
        if (this.options.length) {
          this.$set(this.filter, 'optionId', [
            this.options[0].key,
            this.options[0].subOptions[0].key,
          ])
        }
      }
      this.getData()
    })
  }

  private getCCAClasses(schoolYearId, id): void {
    if (!schoolYearId) return
    this.options = []
    this.filter.optionId = undefined
    operations[this.viewerType](schoolYearId).then(res => {
      if (id !== this.reqId) return
      this.options = res.data
      if (res.data[0]) {
        this.$set(this.filter, 'optionId', res.data[0].key)
        this.getData()
      }
    })
  }

  private changeSchoolYear(schoolYearId): void {
    if (this.viewerType === 'lifeBlock') {
      const id = ++this.reqId
      this.getCCAClasses(schoolYearId, id)
    } else {
      this.getGradePeriods(schoolYearId)
    }
  }

  private changeGradePeriod(value): void {
    this.gradePeriods.forEach(item => {
      item.subOptions.forEach(it => {
        if (it.key === value[1]) {
          this.periodStatus = it.extraValue
        }
      })
    })
    if (this.viewerType === 'lifeBlock') {
      this.getData()
    } else {
      this.getCourseClasses(value[1], ++this.reqId)
    }
  }

  private getData(): void {
    this.data = []
    const optionId = ['advisor', 'lifeBlock'].includes(this.viewerType)
      ? this.filter.optionId
      : this.filter.optionId?.[1]
    if (!this.filter.gradePeriodId[1] || !optionId) return
    this.loading = true
    this.teachers = []
    const request = {
      gradePeriodId: this.filter.gradePeriodId[1],
      type: this.type2Code[this.viewerType],
      groupId: optionId,
    } as any
    GradeController.getCommentDetail(request)
      .then(res => {
        this.data = res.data.students
        this.teachers = res.data.teachers
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private fetchData = debounce(() => {
    this.getData()
  }, 500)

  private async closeDrawer(needSave): Promise<any> {
    if (needSave) {
      const result = await this.saveComment()
      if (result !== 'success') return
    }
    this.drawerInfo = {}
    this.drawerVisible = false
    this.editStudent = undefined
    this.editTeacher = undefined
  }

  private changeMenu({ item, key, keyPath }): void {
    this.studentMenuKey = [key]
    let idx = this.data.findIndex(item => item.studentId === key)
    this.setDetail(this.data[idx], this.editTeacher, idx, true)
  }

  private async setDetail(student, teacher, index, needSave): Promise<any> {
    if (needSave) {
      const result = await this.saveComment()
      if (result !== 'success') return
    }
    this.nextVisible = !this.validateIsLast(index)
    this.lastVisible = !this.validateIsFirst(index)
    this.editStudent = student
    this.editTeacher = teacher
    this.studentMenuKey = [student.studentId]

    this.drawerVisible = true
    this.drawerInfo = {
      ...student,
      comment: student.comments?.[teacher.teacherId],
      teacherName: teacher.name,
      teacherId: teacher.teacherId,
      index: index,
    }
  }

  private saveComment(): any {
    if (!this.drawerVisible) return
    return new Promise(resolve => {
      this.updateLoading = true
      const optionId = ['advisor', 'lifeBlock'].includes(this.viewerType)
        ? this.filter.optionId
        : this.filter.optionId?.[1]
      const request = {
        gradePeriodId: this.filter.gradePeriodId[1],
        type: this.type2Code[this.viewerType],
        studentId: this.drawerInfo.studentId,
        teacherId: this.editTeacher.teacherId,
        content: this.drawerInfo.comment || '',
        groupId: optionId,
      } as any
      const student = this.editStudent
      const teacher = this.editTeacher
      GradeController.saveGradeComment(request)
        .then(res => {
          this.$message.success(this.$tc('common.saveSuccess'))
          student.comments[teacher.teacherId] = request.content
        })
        .catch(err => {
          this.getData()
          console.error(err)
        })
        .finally(() => {
          this.updateLoading = false
          resolve('success')
        })
    })
  }

  private async nextStudent(index): Promise<any> {
    const result = await this.saveComment()
    if (result !== 'success') return

    while (!this.data[index]) {
      index++
    }
    if (index === this.data.length) return
    const student = this.data[index]

    this.setDetail(student, this.editTeacher, index, false)
  }

  private async lastStudent(index): Promise<any> {
    const result = await this.saveComment()
    if (result !== 'success') return
    while (!this.data[index]) {
      index--
    }
    if (index === -1) return
    const student = this.data[index]

    this.setDetail(student, this.editTeacher, index, false)
  }

  private validateIsFirst(index): boolean {
    do {
      index--
      if (index === -1) {
        return true
      }
    } while (!this.data[index])
    return false
  }

  private validateIsLast(index): boolean {
    do {
      index++
      if (index === this.data.length) {
        return true
      }
    } while (!this.data[index])
    return false
  }

  @Watch('viewerType')
  private onTypeChange(newVal): void {
    this.data = []
    this.teachers = []
    this.filter.optionId = undefined
    this.getGradePeriods(this.filter.schoolYearId)
  }
}
