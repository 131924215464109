











































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import FilterGroup from '@/components/filterGroup'
import { filterData } from '@/utils/utils'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import { DropDownController, GradeController, CourseController } from '@/services/request.service'
import { Empty } from 'ant-design-vue'
import { exportXlsx, writeFile } from '@/utils/xlsx'

@Component({
  components: {
    FilterGroup,
  },
})
export default class EGrade extends Vue {
  private filter: any = {
    schoolYearId: undefined,
    gradePeriodId: undefined,
    courseId: [],
  }
  private loading = false
  private standardLoading = false
  private gradeData: Array<any> = []
  private baseData: Array<any> = []
  private schoolYears: Array<any> = []
  private gradePeriods: Array<any> = []
  private realStudentList: any = []
  private courseClasses: Array<any> = []
  private students: Array<any> = []
  private studentId = 0
  private periodStatus = ''
  private standardVisible = false
  private gradeStandard: Array<any> = []
  private baseStandard: Array<any> = []
  private filterData = filterData
  private editMode = false
  private exportLoading = false

  private Empty = Empty

  private get locale(): string {
    return this.$store.state.locale
  }

  private get operationAuths(): any {
    return this.$store.state.operationAuths
  }

  private get courseColumns(): Array<any> {
    return [
      {
        key: 'levelA',
        dataIndex: 'levelA',
        width: 200,
        slots: {
          title: 'levelATitle',
        },
        align: 'left',
        colSpan: 2,
        customCell: (record, index) => {
          return {
            style: {
              backgroundColor: '#d6e4ff',
              color: 'rgba(0, 0, 0, 0.85)',
              borderBottom:
                index === this.gradeData.length ? '1px solid #e8e8e8' : '1px solid #fff',
            },
          }
        },
        customRender: (value, row, index) => {
          return {
            children: value,
            attrs: {
              rowSpan: row.merged ? 0 : row.rowSpan,
            },
          }
        },
      },
      {
        key: 'levelB',
        colSpan: 0,
        scopedSlots: {
          customRender: 'levelB',
        },
      },
      ...this.gradeStandard.map((standard, index) => {
        return {
          key: standard.abbreviation,
          width: 40,
          slots: {
            title: standard.gradeLevelId,
          },
          customHeaderCell: column => {
            return {
              style: {
                padding: 0,
              },
            }
          },
          customCell: (record, recordIndex) => {
            return {
              style: {
                backgroundColor:
                  record.grade === standard.gradeLevelId ? this.calcBg(index) : '#ffffff',
              },
              on: {
                click: () => {
                  // if(standard.gradeLevelId === record.grade)return
                  if (this.periodStatus === 'processing' && this.operationAuths.includes('2074')) {
                    if (this.editMode) {
                      const request = {
                        gradePeriodId: this.filter.gradePeriodId,
                        courseId: this.filter.courseId[1],
                        studentId: this.studentId,
                        gradeClassId: record.levelBId,
                        gradeLevelId:
                          standard.gradeLevelId === record.grade ? null : standard.gradeLevelId,
                      } as any
                      GradeController.saveGradeRecord(request)
                        .then(res => {
                          record.grade =
                            standard.gradeLevelId === record.grade
                              ? undefined
                              : standard.gradeLevelId
                          let students = this.students
                          students.forEach(student => {
                            if (student.studentId === this.studentId) {
                              student.gradedNum =
                                this.gradeData.filter(item => item.grade).length +
                                this.baseData.filter(item => item.grade).length
                            }
                          })
                          this.students = students
                        })
                        .catch(err => {
                          console.error(err)
                        })
                    } else {
                      this.$message.warning(this.$tc('eGrade.editFirst'))
                    }
                  }
                },
              },
            }
          },
        }
      }),
    ]
  }

  private get attitudeColumns(): Array<any> {
    return [
      {
        key: 'levelA',
        dataIndex: 'levelA',
        width: 200,
        slots: {
          title: 'levelATitle',
        },
        align: 'left',
        colSpan: 2,
        customCell: (record, index) => {
          return {
            style: {
              backgroundColor: '#d6e4ff',
              color: 'rgba(0, 0, 0, 0.85)',
              borderBottom:
                index === this.gradeData.length ? '1px solid #e8e8e8' : '1px solid #fff',
            },
          }
        },
        customRender: (value, row, index) => {
          return {
            children: value,
            attrs: {
              rowSpan: row.merged ? 0 : row.rowSpan,
            },
          }
        },
      },
      {
        key: 'levelB',
        colSpan: 0,
        scopedSlots: {
          customRender: 'levelB',
        },
      },
      ...this.baseStandard.map((standard, index) => {
        return {
          key: standard.abbreviation,
          width: 40,
          slots: {
            title: standard.gradeLevelId,
          },
          customHeaderCell: column => {
            return {
              style: {
                padding: 0,
              },
            }
          },
          customCell: record => {
            return {
              style: {
                backgroundColor:
                  record.grade === standard.gradeLevelId ? this.calcBg(index + 1) : '#ffffff',
              },
              on: {
                click: () => {
                  // if(standard.gradeLevelId === record.grade)return
                  if (this.periodStatus === 'processing' && this.operationAuths.includes('2074')) {
                    const request = {
                      gradePeriodId: this.filter.gradePeriodId,
                      courseId: this.filter.courseId[1],
                      studentId: this.studentId,
                      gradeClassId: record.levelBId,
                      gradeLevelId:
                        standard.gradeLevelId === record.grade ? null : standard.gradeLevelId,
                    } as any
                    GradeController.saveGradeRecord(request)
                      .then(res => {
                        record.grade =
                          standard.gradeLevelId === record.grade ? undefined : standard.gradeLevelId
                        let students = this.students
                        students.forEach(student => {
                          if (student.studentId === this.studentId) {
                            student.gradedNum =
                              this.gradeData.filter(item => item.grade).length +
                              this.baseData.filter(item => item.grade).length
                          }
                        })
                        this.students = students
                      })
                      .catch(err => {
                        console.error(err)
                      })
                  }
                },
              },
            }
          },
        }
      }),
    ]
  }

  private get gradeStandardColumns(): Array<any> {
    return [
      {
        key: 'name',
        title: this.$t('eGrade.levelOfMastery'),
        width: '30%',
        dataIndex: 'name',
        customCell: (record, rowIndex) => {
          return {
            style: {
              backgroundColor: this.calcBg(rowIndex),
            },
          }
        },
      },
      {
        key: 'description',
        title: this.$t('eGrade.descriptionOfPerformance'),
        dataIndex: 'description',
      },
    ]
  }

  private get baseStandardColumns(): Array<any> {
    return [
      {
        key: 'name',
        title: this.$t('eGrade.levelOfMastery'),
        width: '30%',
        dataIndex: 'name',
        customCell: (record, rowIndex) => {
          return {
            style: {
              backgroundColor: this.calcBg(rowIndex),
            },
          }
        },
      },
      {
        key: 'description',
        title: this.$t('eGrade.descriptionOfPerformance'),
        dataIndex: 'description',
      },
    ]
  }

  private get completedNum(): number {
    return this.students.filter(student => student.gradeNum === student.gradedNum).length
  }
  private get incompletedNum(): number {
    return this.students.filter(student => student.gradeNum !== student.gradedNum).length
  }

  private created(): void {
    this.getSchoolYears()
  }

  private getSchoolYears(): void {
    DropDownController.getSchoolYearRuleList()
      .then(res => {
        this.schoolYears = res.data
        if (res.data[0]) {
          this.$set(this.filter, 'schoolYearId', res.data[0].key)
          this.getGradePeriods(res.data[0].key)
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getGradePeriods(schoolYearId): void {
    this.gradePeriods = []
    this.courseClasses = []
    this.students = []
    this.$set(this.filter, 'gradePeriodId', undefined)
    this.$set(this.filter, 'courseId', [])
    this.periodStatus = ''
    this.gradeData = []
    this.baseData = []
    DropDownController.getGradePeriods(schoolYearId)
      .then(res => {
        this.gradePeriods = res.data
        if (res.data[0]) {
          this.$set(this.filter, 'gradePeriodId', res.data[0].key)
          this.periodStatus = res.data[0].extraValue
          this.getCourseClasses(res.data[0].key)
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getCourseClasses(gradePeriodId): void {
    this.courseClasses = []
    this.students = []
    this.$set(this.filter, 'courseId', [])
    this.gradeData = []
    this.baseData = []
    DropDownController.getCoursesCascadeByPeriod(gradePeriodId, true)
      .then(res => {
        this.courseClasses = res.data.map(item => {
          return {
            ...item,
            subOptions: item.subOptions.map(it => {
              const { subOptions, ...newIt } = it
              return newIt
            }),
          }
        })
        if (res.data[0]) {
          this.$set(this.filter, 'courseId', [res.data[0].key, res.data[0].subOptions[0].key])
          this.getStudents()
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  private changeSchoolYear(schoolYearId): void {
    this.realStudentList = []
    this.getGradePeriods(schoolYearId)
  }

  private changeGradePeriod(gradePeriodId): void {
    this.periodStatus = this.gradePeriods.filter(
      period => period.key === gradePeriodId
    )[0].extraValue
    this.getCourseClasses(gradePeriodId)
  }

  private getStudents(): void {
    this.students = []
    this.realStudentList = []
    this.studentId = 0
    this.gradeData = []
    this.baseData = []
    const request = {
      gradePeriodId: this.filter.gradePeriodId,
      courseId: this.filter.courseId[1],
    } as any
    GradeController.getGradeStudents(request)
      .then(res => {
        this.students = res.data
        this.realStudentList = this.students
        this.studentId = res.data[0].studentId
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        if (this.studentId) {
          this.getData()
        }
      })
  }

  private getData(): void {
    this.loading = true
    const request = {
      gradePeriodId: this.filter.gradePeriodId || undefined,
      courseId: this.filter.courseId[1] || undefined,
      studentId: this.studentId,
    } as any
    GradeController.getGradeDetail(request)
      .then(res => {
        let gradeData = [] as any
        res.data.courseGrade.gradeClassList.forEach(item => {
          item.childItems.forEach((it, index) => {
            gradeData.push({
              levelA: item.description,
              levelBAbbrevation: it.abbreviation,
              levelB: it.description,
              levelBId: it.gradeClassId,
              grade: it.gradeLevelId,
              rowSpan: item.childItems.length,
              merged: index !== 0,
            })
          })
        })
        this.gradeData = gradeData
        this.gradeStandard = res.data.courseGrade.gradeLevelList
        let baseData = [] as any
        res.data.attitudeGrade.gradeClassList.forEach(item => {
          item.childItems.forEach((it, index) => {
            baseData.push({
              levelA: item.description,
              levelBAbbrevation: it.abbreviation,
              levelB: it.description,
              levelBId: it.gradeClassId,
              grade: it.gradeLevelId,
              rowSpan: item.childItems.length,
              merged: index !== 0,
            })
          })
        })
        this.baseData = baseData
        this.baseStandard = res.data.attitudeGrade.gradeLevelList
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private fetchData = debounce(() => {
    this.getStudents()
  }, 500)

  private calcBg(index): string {
    switch (index) {
      case 0:
        return '#fa8c16'
      case 1:
        return '#ffa940'
      case 2:
        return '#ffc069'
      case 3:
        return '#ffd591'
      case 4:
        return '#ffe7ba'
      case 5:
        return '#d9d9d9'
      default:
        return '#d9d9d9'
    }
  }

  private setGradeAll(gradeLevelId): void {
    if (this.periodStatus === 'processing' && this.operationAuths.includes('2074')) {
      const request = {
        gradePeriodId: this.filter.gradePeriodId,
        courseId: this.filter.courseId[1],
        studentId: this.studentId,
        gradeLevelId,
        type: '1001',
      } as any
      GradeController.saveGradeRecordBatch(request)
        .then(res => {
          this.gradeData = this.gradeData.map(item => {
            return {
              ...item,
              grade: gradeLevelId,
            }
          })
          let students = this.students
          students.forEach(student => {
            if (student.studentId === this.studentId) {
              student.gradedNum =
                this.gradeData.length + this.baseData.filter(item => item.grade).length
            }
          })
          this.students = students
        })
        .catch(err => {
          console.error(err)
        })
    }
  }

  private fetchSetGrade = throttle(this.setGradeAll, 500)

  private fetchSetBase = throttle(this.setBaseAll, 500)

  private setBaseAll(gradeLevelId): void {
    if (this.periodStatus === 'processing' && this.operationAuths.includes('2074')) {
      const request = {
        gradePeriodId: this.filter.gradePeriodId,
        courseId: this.filter.courseId[1],
        studentId: this.studentId,
        gradeLevelId,
        type: '1002',
      } as any
      GradeController.saveGradeRecordBatch(request)
        .then(res => {
          this.baseData = this.baseData.map(item => {
            return {
              ...item,
              grade: gradeLevelId,
            }
          })

          let students = this.students
          students.forEach(student => {
            if (student.studentId === this.studentId) {
              student.gradedNum =
                this.baseData.length + this.gradeData.filter(item => item.grade).length
            }
          })
          this.students = students
        })
        .catch(err => {
          console.error(err)
        })
    }
  }

  private onNameChange(e): void {
    this.getStudentList(e.target.value?.toLowerCase().trim() || '')
  }

  private fetchFilter(name) {
    this.realStudentList = this.students.filter(student =>
      student.name?.toLowerCase().includes(name)
    )
  }

  private getStudentList = debounce(this.fetchFilter, 500)

  private exportAssessments(): void {
    this.exportLoading = true
    let courseClass = {} as any
    this.courseClasses.forEach(course => {
      course.subOptions.forEach(subOption => {
        if (subOption.key === this.filter.courseId[1]) {
          courseClass = subOption
        }
      })
    })
    const schoolYear = this.schoolYears.filter(item => item.key === this.filter.schoolYearId)[0]
    const gradePeriod = this.gradePeriods.filter(item => item.key === this.filter.gradePeriodId)[0]
    GradeController.getGradeExport(this.filter.gradePeriodId, this.filter.courseId[1])
      .then(async res => {
        const students = res.data.students
        const excelColumns = [
          {
            dataIndex: 'levelA',
            title: '',
            width: 50,
          },
          {
            dataIndex: 'levelB',
            title: '',
            width: 100,
          },
          ...students.map((item, index) => {
            return {
              dataIndex: `student-${index}`,
              title: '',
              width: 15,
            }
          }),
        ]
        const course = [] as any
        res.data.courseTable.forEach(levelA => {
          levelA.childItems.forEach((levelB, index) => {
            let grade = {} as any
            for (let i in students) {
              const level = students[i].level
              grade[`student-${i}`] = ''
              for (let j in level) {
                if (j === levelB.gradeClassId + '') {
                  grade[`student-${i}`] = level[j]
                }
              }
            }
            course.push({
              levelA: levelA.description,
              span: index === 0 ? levelA.childItems.length : 0,
              levelB: levelB.description,
              ...grade,
            })
          })
        })
        const attitude = [] as any
        res.data.attitudeTable.forEach(levelA => {
          levelA.childItems.forEach((levelB, index) => {
            let grade = {} as any
            for (let i in students) {
              const level = students[i].level
              grade[`student-${i}`] = ''
              for (let j in level) {
                if (j === levelB.gradeClassId + '') {
                  grade[`student-${i}`] = level[j]
                }
              }
            }
            attitude.push({
              levelA: levelA.description,
              span: index === 0 ? levelA.childItems.length : 0,
              levelB: levelB.description,
              ...grade,
            })
          })
        })
        const dataSource = [...course, {}, ...attitude]
        const file = exportXlsx(excelColumns, dataSource)
        const ws = file.getWorksheet(1)

        ws.insertRow(1, [courseClass.value, '', ...students.map(item => item.studentName)])
        ws.mergeCells('A1:B1')
        ws.eachRow(row => {
          row.height = 30
          row.eachCell({ includeEmpty: true }, cell => {
            cell.border = {
              top: { style: 'thin', color: { argb: 'FF000000' } },
              left: { style: 'thin', color: { argb: 'FF000000' } },
              bottom: { style: 'thin', color: { argb: 'FF000000' } },
              right: { style: 'thin', color: { argb: 'FF000000' } },
            }
            cell.style.alignment = { vertical: 'middle' }
          })
        })
        ws.spliceRows(2, 1)
        dataSource.forEach((item, index) => {
          if (item?.span) {
            ws.mergeCells(`A${index + 2}:A${index + item.span + 1}`)
          }
        })
        await writeFile(
          file,
          `${courseClass.value}_${schoolYear.value} ${
            this.locale === 'zh' ? gradePeriod.value : gradePeriod.enValue
          } report card.xlsx`
        )
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.exportLoading = false
      })
  }

  private toggleEdit(): void {
    if (this.editMode) {
      this.$message.success(this.$tc('common.saveSuccess'))
    }
    this.editMode = !this.editMode
  }
}
