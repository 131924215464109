










































































































import { GradeController } from '@/services/request.service'
import { Vue, Component, Prop, Emit, Model, Watch } from 'vue-property-decorator'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { exportXlsx, writeFile } from '@/utils/xlsx'
import Excel from 'exceljs'
@Component({
  components: {
    FlexTooltip,
  },
})
export default class CommentProgress extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly data!: any

  private loading: any = false
  private exportLoading: any = false
  private progressData: any = {
    list: [],
    all: 0,
    graded: 0,
  }

  private get columns(): any {
    return [
      {
        dataIndex: 'item',
        title: this.$t(`common.${this.type || 'advisory'}`),
        ellipsis: true,
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'teachers',
        title: this.$t('common.teacher'),
        ellipsis: true,
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'done',
        title: this.$t('grading.report.completeStatus'),
        scopedSlots: { customRender: 'status' },
      },
    ]
  }

  private get type(): any {
    return this.data.type
  }

  private get typeCode(): any {
    const typeCode = {
      homeroom: this.$t('router.headTeacherRemark'),
      subjectClass: this.$t('router.subjectRemark'),
      advisory: this.$t('router.advisorRemark'),
      lifeBlock: this.$t('router.LBRemark'),
      assess: this.$t('eGrade.gradeAssessment'),
    }
    return typeCode[this.type]
  }

  private get locale(): any {
    return this.$store.state.locale
  }

  private flattenData(list): any {
    return list.reduce((total, current) => {
      if (current.childItems && current.childItems.length) {
        let all = current.childItems.map((child, index) => ({
          skill: current.description,
          skillValue: child.description,
          abbr: child.abbreviation,
          rowSpan: index === 0 ? current.childItems.length : 0,
        }))
        total = total.concat(all)
      }
      return total
    }, [])
  }

  private getColumns(index, type): any {
    return [
      {
        dataIndex: 'skill',
        slots: { title: index === 0 ? 'tableTitle' : 'skillTitle' },
        customHeaderCell: () => ({ style: { background: index === 0 ? '#fff' : '#fafafa' } }),
        colSpan: index === 0 ? 1 : 2,
        children:
          index === 0
            ? [
                {
                  dataIndex: 'skill',
                  slots: { title: 'skillTitle' },
                  colSpan: 2,
                  width: 200,
                  customRender: (value, row, index) => {
                    return {
                      children: value,
                      attrs: {
                        rowSpan: row.rowSpan,
                      },
                    }
                  },
                },
              ]
            : undefined,
        width: 200,
        customRender: (value, row, index) => {
          return {
            children: value,
            attrs: {
              rowSpan: row.rowSpan,
            },
          }
        },
      },
      {
        dataIndex: 'skillValue',
        scopedSlots: { customRender: 'skillValue' },
        colSpan: 0,
      },
    ]
  }

  private getProgressData(): any {
    this.loading = true
    if (this.type === 'assess') {
      GradeController.getNotGradedClasses(this.data.gradePeriodId, this.data.studentId)
        .then(res => {
          this.progressData = {
            all: res.data.all,
            graded: res.data.graded,
            list: res.data.items.map(course => ({
              ...course,
              nameWithTeachers: `${course.courseName} (${course.teachers})`,
              baseClasses: this.flattenData(course.baseClasses),
              gradeClasses: this.flattenData(course.gradeClasses),
            })),
          }
        })
        .finally(() => (this.loading = false))
    } else {
      GradeController.getCommentCompleteness(
        this.data.gradePeriodId,
        this.data.studentId,
        this.type === 'subjectClass' ? 'course' : this.type
      )
        .then(res => {
          this.progressData = {
            all: res.data.all,
            graded: res.data.completed,
            list: res.data.items,
          }
        })
        .finally(() => (this.loading = false))
    }
  }

  @Watch('visible', { immediate: true })
  private onVisChange(val) {
    if (val) {
      this.getProgressData()
    }
  }

  @Emit('change')
  private closeModal(): boolean {
    return false
  }

  private async exportData(): Promise<void> {
    this.exportLoading = true
    let file: any = null
    if (this.type === 'assess') {
      file = new Excel.Workbook()
      file.addWorksheet('sheet1')
    } else {
      const data = this.progressData.list.map(item => ({
        ...item,
        done: this.$t(`homework.${item.done ? '' : 'in'}complete`),
      }))
      file = exportXlsx(
        this.columns.map(item => ({ ...item, width: 30 })),
        data
      )
    }

    const ws = file.getWorksheet(1)
    if (this.type === 'assess') {
      let index = 1
      this.progressData.list.forEach(course => {
        ws.insertRow(index, [course.nameWithTeachers])
        ws.getRow(index).eachCell((cell, index) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'darkTrellis',
            fgColor: { argb: 'FFE9E9E9' },
            bgColor: { argb: 'FFE9E9E9' },
          } as any
        })
        ws.mergeCells(index, 1, index, 2)
        index++
        ws.insertRow(index, ['学术成就 Academic Achievements'])
        ws.mergeCells(index, 1, index, 2)
        index++
        course.baseClasses.forEach(item => {
          ws.insertRow(index, [item.skill, item.skillValue])
          index++
        })
        ws.insertRow(index, ['学习品行 Learning Efforts'])
        ws.mergeCells(index, 1, index, 2)
        index++
        course.baseClasses.forEach(item => {
          ws.insertRow(index, [item.skill, item.skillValue])
          index++
        })
      })
      ws.getColumn(1).width = 30
      ws.getColumn(2).width = 60
    }

    file
      .getWorksheet(1)
      .getRow(1)
      .eachCell((cell, index) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'darkTrellis',
          fgColor: { argb: 'FFE9E9E9' },
          bgColor: { argb: 'FFE9E9E9' },
        } as any
      })
    ws.eachRow(row => {
      row.height = 30
      row.eachCell((cell: any) => {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } },
        }
        cell.style.alignment = { vertical: 'middle', horizontal: 'center' }
      })
    })
    await writeFile(
      file,
      `${this.data.student}_${
        this.locale ? this.data.period.value : this.data.period.enValue
      }_${this.$t('grading.report.completeStatus', { type: this.typeCode })}.xlsx`
    )
    this.exportLoading = false
  }
}
